import React, { useEffect } from "react"

import Layout from "../components/layout"

const IndexPage = () => {
  useEffect(() => {
    window.location.href = '/medicamentos'
  }, [])

  return (
    <Layout title="Home">
      <div></div>
    </Layout>
  )
}


export default IndexPage
